















































































































import Vue from "vue";
import Search from "@/components/Search.vue";
import SubHeader from "@/views/layout/header/SubHeader.vue";
import MenuFooter from "@/views/layout/footer/MenuFooter.vue";
import { Component } from "vue-property-decorator";
import api from "@/api";
import ProgressTicketModel from "@/models/createTicket/ProgressTicketModel";
import utils from "@/utils";
@Component({
  components: {
    Search,
    SubHeader,
    MenuFooter,
  },
})
export default class Progress extends Vue {
  mounted() {
    if (this.$route.name === "progress") {
      this.getListProgress(this.$route.params.id);
    }
  }
  utils = utils;
  titlePage = "Progress";
  // listImg = [
  //   {
  //     id: "1",
  //     nama_file: "Mesin 1.jpeg",
  //   },
  //   {
  //     id: "2",
  //     nama_file: "Mesin 2.jpeg",
  //   },
  //   {
  //     id: "3",
  //     nama_file: "Mesin 3.jpeg",
  //   },
  //   {
  //     id: "4",
  //     nama_file: "Mesin 4.jpeg",
  //   },
  //   {
  //     id: "5",
  //     nama_file: "Mesin 5.jpeg",
  //   },
  // ];
  // listCategory = [
  //   {
  //     name: "Cake Engine Breaking Down",
  //     id: "1",
  //     status: "approve",
  //   },

  //   {
  //     name: "Cake Engine Breaking Down",
  //     id: "2",
  //     status: "reject",
  //   },
  //   {
  //     name: "Cake Engine Breaking Down",
  //     id: "3",
  //     status: "approve",
  //   },
  //   {
  //     name: "Cake Engine Breaking Down",
  //     id: "4",
  //     status: "approve",
  //   },
  // ];

  listProgress: ProgressTicketModel[] = [];
  getListProgress(idTicket: string) {
    return new Promise((resolve, reject) => {
      api.ticket
        .getListProgress(idTicket)
        .then((response) => {
          if (response.data.code === 200) {
            this.listProgress = [...response.data.data.progress];
            resolve(response);
          }
        })
        .catch((err) => {
          reject(new Error(err));
        });
    });
  }
  rejectTicket() {
    this.$router.push({ name: "form-reject" });
  }
  colorStatus(statusColor: string) {
    let color = "";
    switch (statusColor) {
      case "approve":
        color = "approve";
        break;
      case "reject":
        color = "reject";
        break;
      default:
        color = "reject";
    }
    return color;
  }
  labelStatus(statusColor: string) {
    let label = "";
    switch (statusColor) {
      case "approve":
        label = "Approved";
        break;
      case "reject":
        label = "Rejected";
        break;
      default:
        label = "Rejected";
    }
    return label;
  }
}
